import { Provider } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import ErrorGeneric from '../../app/components/ErrorGeneric'
import { DEFAULT_LANGUAGE, LOGO } from '../../app/environment/const'
import { isDev, pathnameFromSecondParams } from '../../app/helpers/utils'
import { DefaultServiceProvidedInterface } from '../../app/models/ServiceProvider'
import { Breakpoints } from '../../app/service/responsive/mediaQueries'
import { initializeCustomService } from '../loader/InitializeCustomService'
import { GlobalsProvidedVarsInterface, GlobalsResponsive } from '../model/CustomGlobalsProvided'
import { ServiceProvidedInterface } from '../model/CustomServiceProvider'
import { PathNavigatorInterface } from '../service/PathNavigatorService'
import GlobalsContainer from './globals/GlobalsContainer'
import GlobalSpinner from './globals/GlobalSpinner'
import StoreLocatorRouter from './ruoters/StoreLocatorRouter'
interface CustomProviderComponentInterface {
  service: ServiceProvidedInterface
  globals: GlobalsProvidedVarsInterface
  children?: React.ReactChildren
}

export const CustomProvider: React.FC<CustomProviderComponentInterface> = ({ service, globals, children }) => {
  const [provivedContent, setProvidedContent] = useState({
    service: service,
    globals: globals
  })
  const [mainComponent, setMainComponent] = useState(<GlobalSpinner visible={true} />)

  const isDesktop = (() => Breakpoints.isDesktop())()
  const isMobile = (() => Breakpoints.isMobile())()

  const handleMainComponent = (components: any) => {
    if (components && React.Children.count(components) > 0) {
      setMainComponent(components)
    }
  }

  useEffect(() => {
    const { navigator, i18n } = service as DefaultServiceProvidedInterface

    //@ts-ignore
    const NavigatorSelfPushState = (i18n: i18n, navigator: PathNavigatorInterface) => {
      window.history.pushState(null, document.title, navigator.create(i18n.language, pathnameFromSecondParams()))
    }

    const responsive: GlobalsResponsive = {
      isDesktop: isDesktop,
      isMobile: isMobile
    }
    Object.assign(globals, { responsive })

    /**
     * @desc starts control on language change
     */
    if (i18n.isInitialized) {
      window.history.pushState(null, document.title, navigator.create(i18n.language, pathnameFromSecondParams()))

      const headerLogo = document.getElementById('header-logo-link')
      if (headerLogo && LOGO && LOGO.src) {
        //@ts-ignore
        Object.keys(LOGO.src).forEach((language) => {
          if (i18n.language === language && language !== DEFAULT_LANGUAGE) {
            const image: HTMLImageElement = new Image()
            //@ts-ignore
            image.src = `${LOGO.src[language]}`
            image.onload = () => {
              headerLogo.setAttribute('src', image.src)
            }
          }
        })
      }

      i18n.on('languageChanged', (lng: string) => {
        window.history.pushState(null, document.title, navigator.create(lng, pathnameFromSecondParams()))
      })
    }

    // STARTS CUSTOM SERVICE OF DOMAIN
    initializeCustomService({ serviceProvided: service }).subscribe(
      (service: ServiceProvidedInterface) => {
        setProvidedContent({ service: service, globals: globals })
        handleMainComponent(<StoreLocatorRouter key={`StoreLocatorRouter-${Date.now()}`} />)
        if (isDev()) {
          Object.assign(global, {
            service: service,
            globals: globals,
            responsive: responsive
          })
        }
      },
      (err: Error) => {
        let code = undefined
        if (err.message.indexOf('404') !== -1) {
          code = 404
        }

        switch (code) {
          default:
            handleMainComponent(<ErrorGeneric code={code} message={err.message} />)
            break
        }
      }
    )
  }, [])

  return (
    <Provider {...provivedContent}>
      <GlobalsContainer />
      {children}
      {mainComponent}
    </Provider>
  )
}

export default CustomProvider
