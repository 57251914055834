// @ts-ignore
import querystring from 'query-string'

/**
 * @interface
 * @desc structure of accepted & required parametes in querystring
 */
export interface QueryStringParamsAccepted {
  status?: string
  message?: string
}

/**
 * @interface
 * @desc list of method required in querystring service
 */
export interface QueryStringServiceInterface {
  getAcceptedParams(): QueryStringParamsAccepted
}

/**
 * @class
 * @desc query string service, return only accepted params from querystring
 */
export class QueryStringService implements QueryStringServiceInterface {
  constructor(private acceptedKeys: Array<string>) {
    this.acceptedKeys = acceptedKeys
  }
  /**
   * @method
   * @desc private method to get all parsed params
   */
  private parsedParams(): any {
    return querystring.parse(location.search)
  }

  /**
   * @method
   * @desc private method to get specific params
   * @param param
   */
  private getParam(param: string): string {
    const parsed: any = this.parsedParams()
    return parsed[param] || ''
  }

  /**
   * @method
   * @desc private method to get all accepted params
   * @return {QueryStringParamsAccepted}
   */
  getAcceptedParams(): QueryStringParamsAccepted {
    const params = {}

    this.acceptedKeys.forEach((key: string) => {
      const value = this.getParam(key)
      if (value.length > 0) {
        Object.assign(params, { [key]: value })
      }
    })

    return params
  }
}
