import { Observable, Observer } from 'rxjs'

const defaultOptions: object = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }

export class Geolocation {
  static get$(options: any = defaultOptions) {
    return new Observable((observer: Observer<any>) => {
      navigator.geolocation.getCurrentPosition(
        (success: any) => {
          observer.next(success.coords)
          observer.complete()
        },
        (err: any) => {
          observer.error(err)
        },
        options
      )
    })
  }
}

export default Geolocation
