export interface StorageWrapperInterface {
  persist(key: string, data: any): any
  load(key: string): any
}
export class StorageWrapper implements StorageWrapperInterface {
  constructor(private storage: any = window.localStorage) {}
  persist(key: string, data: any) {
    this.storage.setItem(key, data)
  }
  load(key: string) {
    return this.storage.getItem(key)
  }
}
