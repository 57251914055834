import React, { useRef, MutableRefObject, useEffect } from 'react'
import GoogleMapReact, { Coords, MapOptions } from 'google-map-react'
import ErrorGeneric from '../../../app/components/ErrorGeneric'
import { Geolocation } from '../../service/Geolocation'
import { inject, Observer } from 'mobx-react'
import { ProviderServiceInterface } from '../../model/CustomServiceProvider'
import { FacadeApiClientInterface, ShopsManagerCoordsInterface } from '../../api-service/FacadeApiClient'
import { ShopManagerApiResultInterface } from '../../model/api-result/ShopManagerApiResult'
import {
  GOOGLEMAPS_CUSTOM_SHOP_ICON,
  GOOGLEMAPS_CUSTOM_SHOP_ICON_AFFILIATED_SHOP,
  defaultMapsHeight,
  GOOGLE_MAPS_API_KEY
} from '../../environment/const'
import { Subject } from 'rxjs'
import { Trans } from 'react-i18next'
import { GlobalsProvidedInterface, GlobalsResponsive } from '../../model/CustomGlobalsProvided'
import ShopModalCard from './ShopModalCard'
import ShopAdapterToStore from '../../adapter/ShopAdapter'
import { observable } from 'mobx'
import { delay } from 'rxjs/operators'
import Picture from '../../../app/components/Picture'
import { SpinnerComponentInterface } from '../../../app/components/Spinner'
import orangeMarker from '../../../assets/images/svg/map_indicator_orange.svg'
import greyMarker from '../../../assets/images/svg/map_indicator_grey.svg'
import arrowRight from '../../../assets/images/png/btn-arrow.png'
import { getRetailerShopCodeFromCookie } from '../../../app/helpers/utils'

import storeNotFoundIcon from '../../../assets/images/svg/not_found_store_near_you.svg'
import { ModalComponentInterface } from '../generic/Modal'

type googleMapsOptionsStatusTypes = 'not_initialized' | 'ready'

interface googleMapsOptionsInterface {
  center: Coords
  mapReactOptions: MapOptions
  zoom: number
  status: googleMapsOptionsStatusTypes
}

interface GoogleMapObservableInterface {
  maps: any
  map: google.maps.Map | any
}

interface StoreMapComponentInterface {
  readonly scApiClient?: FacadeApiClientInterface
  readonly responsive?: GlobalsResponsive
  readonly globalModal$?: ModalComponentInterface
  globalSpinner$?: SpinnerComponentInterface
}

let MARKER_LIST: google.maps.Marker[] = []

// DEFAULT POSITION IS ROME
const defaultCenter: Coords = {
  lat: 41.902782,
  lng: 12.496366
}

const googleMapsOptions: googleMapsOptionsInterface = {
  center: defaultCenter,
  zoom: 14,
  mapReactOptions: {
    mapTypeId: 'roadmap',
    fullscreenControl: false
  },
  status: 'not_initialized'
}

/**
 * @desc Store map component (contains googlemaps with storelocator)
 */
const StoreMap: React.FC<StoreMapComponentInterface> = inject((states: ProviderServiceInterface & GlobalsProvidedInterface) => ({
  scApiClient: states.service.scApiClient,
  globalSpinner$: states.globals.globalSpinner,
  responsive: states.globals.responsive,
  globalModal$: states.globals.globalModal
}))(({ scApiClient, globalSpinner$, responsive, globalModal$ }) => {
  if (!scApiClient || !globalSpinner$ || !responsive || !globalModal$) {
    return <ErrorGeneric code={110030} i18nKeyMessage={'service_failed_injection'} />
  }

  /**
   * @desc get coordinates of center in googlemaps
   */
  const getMapCenter = (map: any): Coords => {
    const center = map.getCenter()
    return { lat: center.lat(), lng: center.lng() }
  }

  /**
   * @desc automagically get googlemap bounds
   * @param lat
   * @param lng
   */
  const getMapBounds = (map: any, lat: number | null = null, lng: number | null = null): ShopsManagerCoordsInterface => {
    const bounds: google.maps.LatLngBounds = map.getBounds()
    const center: any = bounds.getCenter()

    return {
      lat: lat ? lat : center.lat(),
      lng: lng ? lng : center.lng(),
      neLat: bounds.getNorthEast().lat(),
      neLng: bounds.getNorthEast().lng(),
      swLat: bounds.getSouthWest().lat(),
      swLng: bounds.getSouthWest().lng()
    }
  }

  const existsInMarkerList = (markerOptions: any): boolean => {
    for (let x in MARKER_LIST as any) {
      // @ts-ignore
      const currentMaker = MARKER_LIST[x]
      if (markerOptions.title === currentMaker['title']) {
        return currentMaker
      }
    }
    return false
  }

  const Modal = React.lazy(() => import('./../generic/Modal'))

  const shippingStoreMap: MutableRefObject<HTMLElement | any> = useRef(null)
  const autocompletePlacesInput: MutableRefObject<HTMLInputElement | any> = useRef(null)
  const mapsSearchButton: MutableRefObject<HTMLElement | any> = useRef(null)
  const shopCode: string = getRetailerShopCodeFromCookie() || ''
  const mapsInit$ = new Subject<any>()

  const onloadGoogleMaps = (googlemaps: any): void => {
    mapsInit$.next({
      maps: googlemaps.maps,
      map: googlemaps.map as google.maps.Map
    } as GoogleMapObservableInterface)
  }

  const mapsModal$ = observable<ModalComponentInterface>({
    visible: false,
    component: null,
    container: null
  })
  const shopsSummary$ = observable<any>({ list: [] })

  useEffect(() => {
    const mapCoords$ = new Subject<any>()
    globalSpinner$.visible = true

    mapsInit$.pipe(delay(100)).subscribe(
      (emit: GoogleMapObservableInterface): void => {
        const { maps, map } = emit
        if (!maps || !map) {
          console.error('google maps initialization failed')
          return
        }

        // LIMIT ZOOM
        maps.event.addListener(map, 'zoom_changed', () => {
          if (map.getZoom() < googleMapsOptions.zoom - 2) map.setZoom(googleMapsOptions.zoom)
          mapCoords$.next({
            maps: maps,
            map: map,
            coords: getMapCenter(map)
          })
        })

        // TRY GET GEOLACTION / GEO POSITION
        Geolocation.get$().subscribe(
          (geoData: any) => {
            mapCoords$.next({
              maps: maps,
              map: map,
              coords: { lat: geoData.latitude, lng: geoData.longitude }
            })
          },
          () => {
            console.warn('failed to get geolocation obv position')
            mapCoords$.next({
              maps: maps,
              map: map,
              coords: getMapCenter(map)
            })
            mapsInit$.complete()
          },
          () => {
            mapsInit$.complete()
          }
        )

        // SET CURRENT CENTER OF MAP AFTER DRAGGING
        maps.event.addListener(map, 'dragend', () => {
          const center: Coords = getMapCenter(map)
          mapCoords$.next({
            maps: maps,
            map: map,
            coords: { lat: center.lat, lng: center.lng }
          })
        })

        // AUTOCOMPLETE / SEARCH CITIES
        const autocomplete = new maps.places.Autocomplete(autocompletePlacesInput.current)
        maps.event.addListener(autocomplete, 'place_changed', () => {
          try {
            const place = autocomplete.getPlace()
            mapCoords$.next({
              maps: maps,
              map: map,
              coords: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
              }
            })
          } catch (err) {}
        })

        //CLICK ON SEARCH BUTTON
        mapsSearchButton.current.addEventListener('click', () => {
          mapCoords$.next({
            maps: maps,
            map: map,
            coords: getMapCenter(map)
          })
        })
      },
      () => {
        console.error('google maps obv initialization failed')
      },
      () => {
        globalSpinner$.visible = false
      }
    )

    mapCoords$.subscribe((emit: any) => {
      const { maps, map, coords } = emit
      map as google.maps.Map
      map.setCenter({ ...coords })

      const { lat, lng } = coords
      const shopApi$ =
        shopCode && shopCode.length > 0 ? scApiClient.getShopDetails$(shopCode) : scApiClient.getShops$(getMapBounds(map, lat, lng))

      shopApi$.subscribe((shops: ShopManagerApiResultInterface[]) => {
        //remove old markers
        //cleanMarkerList()

        if (responsive.isDesktop === true) {
          if (shops.length <= 0) {
            globalModal$.dialogStyleOverride = {
              margin: 'auto',
              height: 'auto',
              width: 'auto'
            }
            globalModal$.component = (
              <div className="store-not-found-near-you">
                <Picture src={storeNotFoundIcon} />
                <br />
                <h3>
                  <Trans i18nKey="storemap:sorry">Ci dispiace!</Trans>
                </h3>
                <Trans i18nKey="storemap:store_not_find_near_you">
                  Non abbiamo trovato nessun negozio
                  <br />
                  nelle vicinanze dell'indirizzo che hai inserito.
                  <br />
                  Prova con un altro indirizzo
                </Trans>
              </div>
            )
            globalModal$.visible = true
          }
        } else {
        }

        Object.assign(shopsSummary$, { list: shops })

        shops.forEach((shop: ShopManagerApiResultInterface) => {
          const position = { lat: shop.latitude, lng: shop.longitude }
          const markersOptions: google.maps.MarkerOptions = {
            position: position,
            map: map,
            title: shop.name,
            icon: {
              url: shop.isInspirationPoint ? GOOGLEMAPS_CUSTOM_SHOP_ICON : GOOGLEMAPS_CUSTOM_SHOP_ICON_AFFILIATED_SHOP,
              scaledSize: new google.maps.Size(38, 55)
            }
          }

          if (shop.isPickup) {
            if (existsInMarkerList(markersOptions) === false) {
              const marker = new maps.Marker(markersOptions)
              MARKER_LIST.push(marker)

              marker.addListener('click', () => {
                // ON CLICK ON MAP-MARKER, DONT PUT SELECTION TO API, WAIT TO ESPLICITLLY CLICK "CHOOSE THIS"
                mapCoords$.next({ maps: maps, map: map, coords: position })
                mapsModal$.container = document.getElementById('maps-observer')
                mapsModal$.component = <ShopModalCard {...ShopAdapterToStore(shop)} onClickSelectShop={() => {}} />
                mapsModal$.visible = true
              })
            }
          }
        })

        if (shops.length === 1) {
          map.setCenter({ lat: shops[0].latitude, lng: shops[0].longitude })
        }
      })
    })

    return () => {
      MARKER_LIST = []
    }
  }, [])

  const mapsMarkerStyles = {
    height: responsive.isMobile ? 'auto' : defaultMapsHeight
  }
  if (responsive.isMobile) {
    Object.assign(mapsMarkerStyles, { overflowY: 'hidden' })
  }

  return (
    <>
      <div className="shipping-store-map" ref={shippingStoreMap}>
        <div className={'store-map-search-area ' + (shopCode && shopCode.length > 0 ? 'hide' : '')}>
          <input id="autocomplete-places" ref={autocompletePlacesInput} />
          <button id="maps-search-button" ref={mapsSearchButton}>
            <Trans i18nKey="storemap:find">Trova</Trans>
            <Picture src={arrowRight} />
          </button>
        </div>
        <div className="legend">
          <div className="shop_icon">
            <Picture src={orangeMarker} />
            <Trans i18nKey="storemap:ufficial_shop">Negozio Ufficiale</Trans>
          </div>
          <div className="shop_icon">
            <Picture src={greyMarker} />
            <Trans i18nKey="storemap:affiliated_shop">Negozio Convenzionato</Trans>
          </div>
        </div>
        <div id="maps-container">
          <div id="maps-observer">
            <Observer>
              {() => (
                <Modal
                  visible={mapsModal$.visible}
                  container={mapsModal$.container}
                  onClose={() => (mapsModal$.visible = false)}
                  component={mapsModal$.component}
                />
              )}
            </Observer>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY,
                libraries: 'places'
              }}
              options={googleMapsOptions.mapReactOptions}
              defaultCenter={googleMapsOptions.center as Coords}
              zoom={googleMapsOptions.zoom}
              onGoogleApiLoaded={(google: any) => onloadGoogleMaps(google)}
            ></GoogleMapReact>
          </div>
        </div>
      </div>
    </>
  )
})

export default StoreMap
