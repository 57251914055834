import React from 'react'
import { GOOGLEMAPS_MARKER_CARD_DISTANCE_DEFUALT_UNIT } from '../../environment/const'
import { Trans } from 'react-i18next'
import Picture from '../../../app/components/Picture'
import phoneIcon from '../../../assets/images/svg/button_phone.svg'
import mailIcon from '../../../assets/images/svg/button_mail.svg'

interface ShopModalCardInterface {
  name: string
  city: string
  isInspirationPoint: boolean
  email: string
  phone: string
  street: string
  distance: number
  onClickSelectShop?: any
}
const ShopModalCard: React.SFC<ShopModalCardInterface> = ({
  name,
  city,
  isInspirationPoint,
  email,
  phone,
  street,
  distance,
  onClickSelectShop
}) => {
  return (
    <div className="ShopModalCard">
      <div className="top-container">
        <label className="title">{name}</label>
        <br />
        {isInspirationPoint && (
          <b>
            <Trans i18nKey="storemap:official">Ufficiale</Trans>
          </b>
        )}
        <br />
        {street.toLowerCase()}
        <br />
        {city.toLowerCase()}{' '}
        <label className="distance_label">
          {distance}
          {GOOGLEMAPS_MARKER_CARD_DISTANCE_DEFUALT_UNIT}
        </label>
      </div>
      <div className="bottom-container">
        <div className="contact-container">
          {phone && (
            <a href={'tel:' + phone}>
              <div className="round-icon phone">
                <Picture src={phoneIcon} />
              </div>
              <Trans i18nKey="storemap:phone">Telefono</Trans>
            </a>
          )}
          {email && (
            <a href={'mailto:' + email}>
              <div className="round-icon mail">
                <Picture src={mailIcon} />
              </div>
              <Trans i18nKey="storemap:mail">E-Mail</Trans>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
export default ShopModalCard
