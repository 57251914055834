import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { XHROptions } from '../../../app/service/XHRRequest/XHRRequestObservable'
import ApiEnv from '../../environment/api-env'
import { ShopManagerApiResultInterface } from '../../model/api-result/ShopManagerApiResult'
import { AbstractApiClient } from '../AbstractApiClient'
import { ShopsManagerCoordsInterface } from '../FacadeApiClient'

export interface ShopsManagerApiClientInterface {
  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]>
  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]>
}

export class ShopsManagerApiClient extends AbstractApiClient {
  private configurationCode: string = ''
  constructor({ xhrRequestObv, querystring, configurationCode }: any) {
    super(xhrRequestObv, querystring)
    this.configurationCode = configurationCode
  }
  /**
   * @desc get shop in specifics area delimited by coords
   * @param {ShopsManagerCoordsInterface} coords
   * @return {Observable}
   */
  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]> {
    const url = `${ApiEnv.shopsManager.url}`.replace('{configuration}', this.configurationCode)
    const params: XHROptions = {
      url: `${url}?lng=${coords.lng}&lat=${coords.lat}&neLng=${coords.neLng}&neLat=${coords.neLat}&swLng=${coords.swLng}&swLat=${coords.swLat}`
    }

    return this.get$(params)
  }

  /**
   * @desc get shop in specifics area delimited by coords
   * @param {ShopsManagerCoordsInterface} coords
   * @return {Observable}
   */
  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]> {
    const url = `${ApiEnv.shopsManager.url}`.replace('{configuration}', this.configurationCode)
    const params: XHROptions = {
      url: `${url}${shopCode}`
    }

    return this.get$(params).pipe(
      mergeMap((emit: ShopManagerApiResultInterface): Observable<ShopManagerApiResultInterface[]> => of([emit]))
    )
  }
}
