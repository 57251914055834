/**
 * DOMAINS ENVIRONMENT VARIABLE
 */

import mapStoreOfficial from './../../assets/images/svg/map_store_orange.svg'
import mapStoreAffiliated from './../../assets/images/svg/map_store_grey.svg'
export const SHOPPING_CART_ITEMKEY_STORAGE: string = 'phs-shopping-cart-consumer'
export const SHOPPING_CART_GEOGRAPHICS_ITEMKEY_STORAGE: string = 'phs-shopping-cart-consumer-geographics'
export const X_API_KEY: string = `${process.env.REACT_APP_X_API_KEY}`
export const XHR_TIMEOUT: number = (Number(process.env.REACT_APP_XHR_TIMEOUT) || 0) as number
export const XHR_RETRY: number = (Number(process.env.REACT_APP_XHR_RETRY) || 0) as number
export const GOOGLEMAPS_CUSTOM_SHOP_ICON = mapStoreOfficial
export const GOOGLEMAPS_CUSTOM_SHOP_ICON_AFFILIATED_SHOP = mapStoreAffiliated
export const GOOGLEMAPS_MARKER_CARD_DISTANCE_DEFUALT_UNIT: string = 'm'

export const SHIPPING_METHOD_PICKUP: acceptedShippingMethodNames = 'PickUpStore'
export const SHIPPING_METHOD_MAILORDER: acceptedShippingMethodNames = 'MailOrder'
export type acceptedShippingMethodNames = 'PickUpStore' | 'MailOrder' | string
export const acceptedShippingMethod = [`${SHIPPING_METHOD_PICKUP}`, `${SHIPPING_METHOD_MAILORDER}`]

export const OPENED_CLASS: string = 'opened'
export const defaultMapsHeight: string = '500px'

export const SHIPPING_METHODS_STORAGE_KEY: string = 'shipping_method_list'

export const GOOGLE_MAPS_API_KEY: string = process.env.REACT_APP_GOOGLE_API_KEY || ''

export const DEFAULT_DISTRIBUTOR_CODE = process.env.REACT_APP_DEFAULT_DISTRIBUTOR_CODE || ''
export const DEFAULT_CONFIGURATION_CODE = process.env.REACT_APP_DEFAULT_CONFIGURATION_CODE || ''

export const SOFTWARE_CODE = process.env.REACT_APP_SOFTWARE_CODE || ''
export const SOFTWARE_VERSION = process.env.REACT_APP_SOFTWARE_VERSION || ''

export const ACCEPTED_QUERY_STRING_KEY = ['status', 'message']
export const NAVIGATOR_DENIED_QUERY_STRING_KEY = ['token']
