import React from 'react'
import { observer, inject } from 'mobx-react'
import { GlobalsProvidedInterface } from '../../model/CustomGlobalsProvided'
import Modal, { ModalComponentInterface } from '../generic/Modal'

interface GlobalsContainerInterface {
  globalModal$?: ModalComponentInterface
  children?: any
}

export const GlobalsContainer: React.FC<GlobalsContainerInterface> = inject((states: GlobalsProvidedInterface) => ({
  globalModal$: states.globals.globalModal as ModalComponentInterface
}))(
  observer(({ globalModal$, children }) => {
    if (!globalModal$) {
      const ErrorGeneric = React.lazy(() => import('./../../../app/components/ErrorGeneric'))
      return <ErrorGeneric code={110013} i18nKeyMessage={'service_failed_injection'} />
    }

    const setModalVisbile = (condition: boolean) => {
      Object.assign(
        globalModal$,
        Object.assign(globalModal$, { visible: condition, hideClose: false, dialogStyleOverride: {}, styleOverride: {} })
      )
    }

    return (
      <div className="phs-consumer-cart phs-container tc">
        <Modal onClose={() => setModalVisbile(false)} {...globalModal$}></Modal>
        {children}
      </div>
    )
  })
)

export default GlobalsContainer
