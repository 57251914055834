import React, { useEffect, useState } from 'react'
import { FALLBACK_THUMBNAIL } from '../../app/environment/const'

/**
 * The shape of the props
 */
type ImageProps = {
  alt?: string
  className?: string
  src: string
}

/**
 * Component to render an image
 * @param alt - The alt text of the image
 * @param src - The src of the image
 * @constructor
 */
const ImageComponent: React.FC<ImageProps> = ({ alt, src, className }) => {
  const [image, setImage] = useState(src)

  useEffect(() => {
    const image: HTMLImageElement = new Image()
    image.src = `${src}`

    image.onload = () => {
      setImage(src)
    }
    image.onerror = () => {
      setImage(FALLBACK_THUMBNAIL)
    }
  }, [])

  return <img className={className} alt={alt} src={image} />
}

export default ImageComponent
