import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'

export interface ModalComponentInterface {
  children?: any
  component?: any
  visible?: boolean
  container?: any
  ref?: any
  styleOverride?: object
  onClose?: any
  confirm?: boolean
  onConfirm?: any
  onCancel?: any
  hideClose?: boolean
  dialogStyleOverride?: object
}

export const Modal: React.FC<ModalComponentInterface> = ({
  children,
  component,
  visible,
  container,
  styleOverride,
  onClose,
  confirm,
  onConfirm,
  onCancel,
  hideClose,
  dialogStyleOverride
}) => {
  const [style, setStyle] = useState(styleOverride || {})

  const closeModal = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  const confirmModal = () => {
    if (typeof onConfirm === 'function') {
      onConfirm()
    }
    closeModal()
  }

  const cancelModal = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    closeModal()
  }

  useEffect(() => {
    if (visible === false) {
      // on close lost custom configuration
      confirm = false
      styleOverride = {}
      hideClose = false
      dialogStyleOverride = {}
      onClose = undefined
      onConfirm = undefined
      onCancel = undefined
      component = <></>
    }

    if (container) {
      container as HTMLElement

      const currentStyle = {
        position: 'fixed',
        top: `${container.offsetTop}px`,
        left: `${container.offsetLeft}px`,
        height: `${container.clientHeight}px`,
        width: `${container.clientWidth}px`
      }

      Object.assign(currentStyle, styleOverride)
      setStyle(currentStyle)
    }
  }, [visible])
  return (
    <div className={'modal-container ' + (visible ? 'opened ' : '') + (container ? 'conteinerized ' : '')} style={style}>
      <div className="dialog" style={dialogStyleOverride}>
        {hideClose !== true && (
          <div className="close-button" onClick={() => closeModal()}>
            ✕
          </div>
        )}
        <div className="component-area">
          {component}
          {children}
          {confirm && confirm === true && (
            <div className="confirm-button-container">
              <button className="phs-button secondary hollow cancel" onClick={() => cancelModal()}>
                <Trans i18nKey="modal:cancel">Annulla</Trans>
              </button>
              <button className="phs-button secondary confirm" onClick={() => confirmModal()}>
                <Trans i18nKey="modal:confirm">Conferma</Trans>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
