import React from 'react'
import { isIE } from '../helpers/utils'
import IPicture from '../models/Picture'
import Image from './Image'

/**
 * The picture component
 * @param props
 * @constructor
 */
const Picture: React.FC<IPicture> = ({ alt, sources, src, className }) => {
  if (isIE()) {
    return <Image alt={alt} src={src} />
  }

  return (
    <picture>
      {sources &&
        sources.map((source: any, index: number) => (
          <source key={`${Date.now()}-${source.src}-${index}`} srcSet={source.src} media={source.dim} />
        ))}
      <Image className={className} alt={alt} src={src} />
    </picture>
  )
}

export default Picture
