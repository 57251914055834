import { ShopManagerApiResultInterface } from '../model/api-result/ShopManagerApiResult'
import { ShopManagerInterface } from '../model/ShopManagerModel'

export const ShopManagerApiAdapter = (shop: ShopManagerApiResultInterface): ShopManagerInterface => {
  return Object.assign(
    {},
    {
      area: shop.area,
      city: shop.city,
      country: shop.country,
      distance: shop.distance ? shop.distance : 0,
      email: shop.email,
      iplabsRetailerId: shop.iplabsRetailerId,
      isInspirationPoint: shop.isInspirationPoint,
      isMailorder: shop.isMailorder,
      isPickup: shop.isPickup,
      latitude: shop.latitude,
      longitude: shop.longitude,
      name: shop.name,
      paymentTypes: shop.paymentTypes,
      phone: shop.phone,
      shopCode: shop.shopCode,
      shopType: shop.shopType,
      street: shop.street,
      zipCode: shop.zipCode
    }
  )
}

export default ShopManagerApiAdapter
