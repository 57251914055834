import React, { useEffect, useState } from 'react'
import { inject } from 'mobx-react'
import { ProviderServiceInterface } from '../../domain/model/CustomServiceProvider'
import { i18n } from 'i18next'
import { Trans } from 'react-i18next'
import { GlobalsProvidedInterface } from '../../domain/model/CustomGlobalsProvided'
import { SpinnerComponentInterface } from './Spinner'
import Error404 from './Error404'

import errorIcon from './../../assets/images/svg/error_icon.svg'
import Picture from './Picture'
import * as Sentry from '@sentry/browser'
import { isDev } from '../helpers/utils'

interface ErrorComponentInterface {
  readonly i18n?: i18n
  globalSpinner$?: SpinnerComponentInterface
  i18nKeyMessage?: string
  message?: string
  children?: any
  onload?: any
  title?: string
  subTitle?: string
  code?: number
  buttonAction?: Function
  buttonLabel?: string
  retry?: boolean
  sentry?: typeof Sentry
}

interface ErrorMessageInterface extends ErrorComponentInterface {
  readonly i18n?: i18n
}

export const ErrorGeneric: React.FC<ErrorMessageInterface> = inject((states: ProviderServiceInterface & GlobalsProvidedInterface) => ({
  i18n: states.service.i18n,
  globalSpinner$: states.globals.globalSpinner,
  sentry: states.service.sentry
}))(
  ({
    i18n,
    children,
    message,
    onload,
    i18nKeyMessage,
    title,
    globalSpinner$,
    code,
    buttonAction,
    buttonLabel,
    retry,
    subTitle,
    sentry
  }) => {
    const [errorComponent, setErrorComponent] = useState(<></>)

    useEffect(() => {
      globalSpinner$ ? (globalSpinner$.visible = false) : null
      if (typeof onload === 'function') {
        onload()
      }

      switch (code) {
        case 404:
          setErrorComponent(<Error404 />)
          break
        default:
          setErrorComponent(
            <>
              {code && code === 404 && <Error404 />}
              <br />
              {i18n && i18nKeyMessage && i18n.getResource(i18n.language, 'error', i18nKeyMessage)}
              {!code && <Picture className={'error-image'} src={errorIcon} />}
              <br />
              <br />
              <div className={isDev() ? '' : 'hide'}>{!children && message}</div>
              {children}
              {subTitle}
              {i18nKeyMessage && !subTitle && <Trans i18nKey={`error:${i18nKeyMessage}`}>Errore iniezione servizi</Trans>}
            </>
          )
          break
      }

      if (message) {
        console.error(message)
      }

      if (sentry) {
        const sentryError = new Error(message)
        sentryError.name = `Error Generic ${code || ''}`
        sentry.captureException(sentryError)
      }
    }, [])

    return (
      <div className="phs-consumer-cart phs-container tc">
        {!title && (
          <h1>
            <Trans i18nKey="error:error">Errore</Trans>
          </h1>
        )}
        <h3>{title}</h3>
        <div>{errorComponent}</div>
        <br />
        <br />
        {retry !== false && (
          <button className="phs-button secondary" onClick={() => (buttonAction ? buttonAction() : window.location.reload())}>
            {buttonLabel ? buttonLabel : <Trans i18nKey="error:retry">Riprova</Trans>}
          </button>
        )}
      </div>
    )
  }
)

export default ErrorGeneric
