import { Observable, throwError } from 'rxjs'
import { retry as retryOperator, map } from 'rxjs/operators'
import { bodyParam, XHROptions, XHRRequestObservableInterface } from '../../app/service/XHRRequest/XHRRequestObservable'
import { XHR_RETRY } from '../environment/const'

export abstract class AbstractApiClient {
  constructor(public xhrRequestObv: XHRRequestObservableInterface, public querystring: string = '') {
    this.xhrRequestObv = xhrRequestObv
    this.querystring = querystring
  }

  /**
   * @desc return json from xhr call encapsualted in the observable
   * @param url
   * @return {Observable}
   */
  protected get$(params: XHROptions, retry: boolean = true): Observable<any> {
    if (params.url.indexOf('?') !== -1) {
      Object.assign(params, { url: `${params.url}&${this.querystring}` })
    } else {
      Object.assign(params, { url: `${params.url}?${this.querystring}` })
    }

    if (!params.url || (params.url && params.url.length <= 0)) {
      return throwError(`Failed to send xhr call, URL is invalid: ${JSON.stringify(params)}`)
    }

    let xhr$ = this.xhrRequestObv.XHR$(params)

    if (retry) {
      xhr$ = xhr$.pipe(retryOperator(XHR_RETRY))
    }

    return xhr$.pipe(map((response: any) => response.data))
  }

  protected getBodyParams(data: Object) {
    if (Object.keys.length <= 0) {
      return []
    }
    const bodyParams: bodyParam[] = []
    for (let key in data) {
      //@ts-ignore
      bodyParams.push({ key: key, value: data[key] })
    }
    return bodyParams
  }
}
