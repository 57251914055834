import React from 'react'
import { inject } from 'mobx-react'
import { useEffect } from 'react'
import { SpinnerComponentInterface } from '../../../app/components/Spinner'
import { GlobalsProvidedInterface } from '../../model/CustomGlobalsProvided'

interface GlobalSpinnerComponentInterface {
  readonly globalSpinner$?: SpinnerComponentInterface
  visible?: boolean
}

export const GlobalSpinner: React.FC<GlobalSpinnerComponentInterface> = inject((states: GlobalsProvidedInterface) => ({
  globalSpinner$: states.globals.globalSpinner
}))(({ globalSpinner$, visible }) => {
  useEffect(() => {
    if (globalSpinner$) {
      globalSpinner$.visible = visible
    }

    /*
    return () => {
      if (globalSpinner$ && visible === true) {
        globalSpinner$.visible = false
      }
    }
    */
  }, [])

  return <></>
})

export default GlobalSpinner
