import { XHRRequestObservableInterface, CustomRequestHeaderItemInterface } from '../../app/service/XHRRequest/XHRRequestObservable'
import { SOFTWARE_CODE, SOFTWARE_VERSION, X_API_KEY } from '../environment/const'
import { i18n } from 'i18next'
import { Observable } from 'rxjs'
import { ShopsManagerApiClient, ShopsManagerApiClientInterface } from './FacadeApiClientSubsytem/ShopsManagerApiClient'
import { ShopManagerApiResultInterface } from '../model/api-result/ShopManagerApiResult'

export type geographicsTypes = 'areas' | 'countries'

export interface ShopsManagerCoordsInterface {
  lat: number
  lng: number
  neLat: number
  neLng: number
  swLat: number
  swLng: number
}

export interface FacadeApiClientInterface {
  setDistributor(distributor: string): void
  getDistributor(): string
  setConfigurationCode(configurationCode: string): void
  getConfigurationCode(): string
  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]>
  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]>
  setDistributor(distributor: string): void
  setConfigurationCode(configurationCode: string): void
}

/**
 * @desc configurable gruop of compatible subsystem, based on AbstractApiClient
 */
export class FacadeApiClient implements FacadeApiClientInterface {
  public distributor: string
  public configurationCode: string

  constructor(i18n: i18n, public xhrRequestObv: XHRRequestObservableInterface) {
    this.distributor = ''
    this.configurationCode = ''
    this.xhrRequestObv = xhrRequestObv

    const xApiKey: string = X_API_KEY
    const xApiKeyHeader: CustomRequestHeaderItemInterface = { key: 'x-api-key', value: xApiKey }
    const acceptLanguage: CustomRequestHeaderItemInterface = { key: 'Accept-Language', value: `${i18n.language}` }

    this.xhrRequestObv.addCustomRequestHeaderItem(xApiKeyHeader)
    this.xhrRequestObv.addCustomRequestHeaderItem(acceptLanguage)
  }

  /*
   * FACADE - START SUBSYSTEMS
   */

  private subsystemShopsManagerApiClient(): ShopsManagerApiClientInterface {
    return new ShopsManagerApiClient({ ...this.getSubsystemArgs() })
  }

  /*
   * FACADE - END SUBSYSTEMS
   */

  /**
   * @desc generate params for compatibile subsystem
   */
  private getSubsystemArgs() {
    return {
      xhrRequestObv: this.xhrRequestObv,
      querystring: `softwareCode=${SOFTWARE_CODE}&softwareVersion=${SOFTWARE_VERSION}&distributor=${this.distributor}`,
      configurationCode: this.configurationCode
    }
  }

  // PUBLIC SETTERS AND GETTERS OF FACADE CONFIGURATIONS

  setDistributor(distributor: string): void {
    this.distributor = distributor
  }

  getDistributor(): string {
    return this.distributor
  }

  setConfigurationCode(configurationCode: string) {
    this.configurationCode = configurationCode
  }

  getConfigurationCode(): string {
    return this.configurationCode
  }
  // PUBLIC SUBSYSTEM API CLIENT FUNCTIONS

  getShops$(coords: ShopsManagerCoordsInterface): Observable<ShopManagerApiResultInterface[]> {
    return this.subsystemShopsManagerApiClient().getShops$(coords)
  }

  getShopDetails$(shopCode: string): Observable<ShopManagerApiResultInterface[]> {
    return this.subsystemShopsManagerApiClient().getShopDetails$(shopCode)
  }
}
