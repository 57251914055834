import React from 'react'

import Picture from './Picture'
import error404 from './../../assets/images/webp/404_pc_photo_xxl.webp'
import error404IE11 from './../../assets/images/jpg/404_pc_photo_xxl.jpg'
import error404mobile from './../../assets/images/webp/404_pc_photo.webp'
import error404mobileIE11 from './../../assets/images/jpg/404_pc_photo.jpg'
import error404text from './../../assets/images/svg/404_text.svg'
import { Desktop, Mobile } from '../service/responsive/mediaQueries'
import { isIE } from '../helpers/utils'

export const Error404 = () => {
  return (
    <div className="grid-404 grid-x xxsmall-my-2 large-my-5 phs-alg-center-middle">
      <Desktop>
        <div className="image cell xxsmall-12 tc medium-6 medium-tr">
          <Picture src={isIE() ? error404IE11 : error404} />
        </div>
        <div className="text cell xxsmall-12 tc medium-6 medium-tr">
          <Picture src={error404text} />
        </div>
      </Desktop>
      <Mobile>
        <div className="image cell xxsmall-12 tc medium-6 medium-tr">
          <Picture src={isIE() ? error404mobileIE11 : error404mobile} />
        </div>
      </Mobile>
    </div>
  )
}

export default Error404
