import { Observable, Observer } from 'rxjs'

// SERVICE TO MANAGE XHR REQEUST WITH OBSERVABLE
import { XHRRequestObservable } from '../../app/service/XHRRequest/XHRRequestObservable'
import { FacadeApiClientInterface, FacadeApiClient } from '../api-service/FacadeApiClient'

// INTERFACE OF PROVIDER FOR INJECT SERVICE
import { ServiceProvidedInterface } from '../model/CustomServiceProvider'
import { DefaultServiceProvidedInterface } from '../../app/models/ServiceProvider'
import { getDistributorCodeFromGdoCookie, getGDOInfoData } from '../../app/helpers/utils'
import { DEFAULT_CONFIGURATION_CODE, DEFAULT_DISTRIBUTOR_CODE } from '../environment/const'

interface initializeCustomService {
  serviceProvided: DefaultServiceProvidedInterface
}

export const initializeCustomService = ({ serviceProvided }: initializeCustomService): Observable<ServiceProvidedInterface> => {
  return new Observable((observer: Observer<any>) => {
    const { i18n } = serviceProvided

    /**
     * Create Shopping cart client with Payload and xhr observable service
     */

    const scApiClient: FacadeApiClientInterface = new FacadeApiClient(i18n, new XHRRequestObservable())
    const distributorCode = getDistributorCodeFromGdoCookie()
    if (distributorCode && distributorCode.length > 0) {
      scApiClient.setDistributor(distributorCode)
    }

    const gdoInfoData = getGDOInfoData()
    const distributor = gdoInfoData.distributorCode || DEFAULT_DISTRIBUTOR_CODE
    const configurationCode = gdoInfoData.configurationCode || DEFAULT_CONFIGURATION_CODE

    /**
     * @desc add distributor in root store
     */
    scApiClient.setDistributor(distributor)
    scApiClient.setConfigurationCode(configurationCode)

    /**
     * @desc list of provided service via injection with mobx provider component
     */
    const ShoppingCartService: any = Object.assign(serviceProvided, {
      scApiClient: scApiClient
    })

    observer.next(ShoppingCartService)
  })
}

export default initializeCustomService
