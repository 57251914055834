import { QueryStringParamsAccepted } from '../../app/service/queryString/queryStringService'

/**
 * @desc necessary to navigate with path structure required from application and internal localization
 */

export interface ParamsInterface {
  guid?: string
  status?: string
  message?: string
}

/**
 * @interface PathNavigatorInterface
 */
export interface PathNavigatorInterface {
  create(lng: string, ...args: any): string
  getParams(): ParamsInterface
  setParams(object: ParamsInterface): void
  assignParams(object: ParamsInterface): void
}

/**
 * @class
 * @desc static class required to get path of routing based on language key (translations key)
 */
export class PathNavigator implements PathNavigatorInterface {
  constructor(private params: QueryStringParamsAccepted, private acceptedKeys?: Array<string>) {
    this.params = params
    this.acceptedKeys = acceptedKeys
  }

  /**
   * @desc get params from service
   */
  getParams(): ParamsInterface {
    return this.filterParams(this.params)
  }

  private filterParams(params: QueryStringParamsAccepted): ParamsInterface {
    if (!this.acceptedKeys) {
      return this.params
    }
    const newParams = {}
    Object.keys(params).forEach((key: string) => {
      if (this.acceptedKeys && this.acceptedKeys.includes(key)) {
        //@ts-ignore
        Object.assign(newParams, { [key]: params[key] })
      }
    })
    return newParams
  }

  /**
   * @desc assign new params
   * @param object
   */
  assignParams(object: ParamsInterface): void {
    Object.assign(this.params, object)
  }

  /**
   * @desc set params in service
   * @param param0 { guid: guid, token: token }
   */
  setParams(object: ParamsInterface): void {
    this.params = Object.assign({}, object) as QueryStringParamsAccepted
  }

  /**
   * @desc goto to path, accept a undefined number of args for compose the destination path
   * @param lng
   * @param args
   */
  create = (lng: string, ...args: any[]): string => {
    let pathList: string = ''
    args.forEach((arg: any) => {
      if (Array.isArray(arg)) {
        arg.forEach((item: string) => {
          pathList += `/${item}`
        })
      } else {
        if (arg && arg.length > 0) {
          pathList += `/${arg}`
        }
      }
    })
    const basepath: string[] = [`/${lng}${pathList}`]
    const newParams: string[] = []

    Object.keys(this.getParams()).forEach((key: string): void => {
      // @ts-ignore
      const value: string = `${this.getParams()[key]}`
      if (value && value.length > 0) {
        newParams.push(`${key}=${value}`)
      }
    })

    if (newParams.length > 0) {
      basepath.push(newParams.join('&'))
    }

    const destinationPath: string = basepath.length > 0 ? basepath.join('?') : basepath.join('')

    return `${destinationPath}`
  }
}
